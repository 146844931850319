import React from 'react';
import cx from 'classnames';
import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { curlyQuotes } from 'utils/text';

import { Button, Img } from 'components/base';
import SectionButton from 'components/SectionButton';
import AuthorButtons from 'components/AuthorButtons';

import Language from 'constants/Language';

import { ArticleLink, LayoutHero3 as ILayoutHero3 } from 'sharedTypes';
import get from 'lodash/get';

interface Props {
  layoutModule: ILayoutHero3;
  hideSectionIcon?: boolean;
}

const LayoutHero3: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const { article, showIntroText } = layoutModule;
  const image = article.featuredImage;
  const isFiction = get(article, 'isFiction', false);
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  return (
    <div className="LayoutHero3 content-padding-x sm:mt1_5 inner-content-max-width mxauto">
      <div
        className={cx(
          'LayoutHero3__container teaser-widget-container layout-widget-container color-black',
          {
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        <div className="flex flex-col md:flex-row md:items-center">
          {image && image.src && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: article.title,
              })}
              to={linkTo}
              className={cx('w100 h100', {
                'fiction-teaser-img': isFiction,
              })}
              containerClassName="lg:h100 col-12 md:col-6 lg:mr2_25"
              wrap={true}
            >
              <div className="w100 h100 relative radius-xs overflow-hidden">
                <Img
                  className="LayoutHero3__image grayscale-img w100 h100 fit-cover radius-xs"
                  alt={image.alt || image.caption || ''}
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(image, 1920)}
                  srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                  dimensions={image.metadata && image.metadata.dimensions}
                  crop={image.crop}
                />
                <Img
                  className="LayoutHero3__image color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs"
                  alt={image.alt || image.caption || ''}
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(image, 1920)}
                  dimensions={image.metadata && image.metadata.dimensions}
                  srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                />
              </div>
            </Button>
          )}
          <div className="col-12 md:col-6 flex flex-col items-center">
            <div className="flex items-center">
              <div className="LayoutHero3__title-container relative flex flex-col items-center my1 lg:my2_5">
                {hasSection && article.section && (
                  <SectionButton
                    className="mb1 lg:mb2_25"
                    section={article.section}
                    hideIcon={hideSectionIcon}
                    isFictionTeaser={isFiction}
                  />
                )}
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: article.title,
                  })}
                  to={linkTo}
                  containerClassName="mb1 text-center color-black"
                  wrap={true}
                >
                  <span className="linked-border-bottom-lg-on-hover-red text-section-header-xl itc-cushing font-300">
                    {curlyQuotes(article.title)}
                  </span>
                </Button>
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: article.title,
                  })}
                  to={linkTo}
                  containerClassName="mb1 text-center"
                  wrap={true}
                >
                  <span className="text-section-details-sm graebenbach font-400 color-gray-darker">
                    {curlyQuotes(article.dek)}
                  </span>
                </Button>
                {!!article.authors.length && (
                  <AuthorButtons
                    className="text-center"
                    authors={article.authors}
                  />
                )}
              </div>
            </div>

            {!!showIntroText && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: article.title,
                })}
                to={linkTo}
                containerClassName="mt1_5 lg:mt_5 md:none lg:flex"
                wrap={true}
              >
                <div
                  className={cx(
                    'LayoutHero3__excerpt-container relative color-black pt1 lg:pt2_25',
                    {
                      'fiction-teaser-border': isFiction,
                    }
                  )}
                >
                  <p className="text-layout-dropcaps text-section-body-sm graebenbach font-400">
                    {curlyQuotes(article.excerpt)}
                  </p>
                  <div className="pt1_5">
                    <span className="LayoutHero3__continue-reading relative color-black graebenbach text-section-body-sm font-400">
                      {Language.t('Global.continueReading')} &rarr;&#xFE0E;
                    </span>
                  </div>
                </div>
              </Button>
            )}
          </div>
        </div>

        {!!showIntroText && (
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            containerClassName="mt1_5 lg:mt_5 none md:flex lg:none"
            wrap={true}
          >
            <div className="LayoutHero3__excerpt-container relative color-black pt1 lg:pt2_25">
              <p className="text-layout-dropcaps text-section-body-sm graebenbach font-400">
                {curlyQuotes(article.excerpt)}
              </p>
              <div className="pt1_5">
                <span className="LayoutHero3__continue-reading relative color-black graebenbach text-section-body-sm font-400">
                  {Language.t('Global.continueReading')} &rarr;&#xFE0E;
                </span>
              </div>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default LayoutHero3;
