import React from 'react';

import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import generateArticleUrl from 'utils/generateArticleUrl';
import { curlyQuotes } from 'utils/text';

import { Button, Img } from 'components/base';
import SectionButton from 'components/SectionButton';
import AuthorButtons from 'components/AuthorButtons';

import Language from 'constants/Language';

import {
  ArticleLink,
  FeatureArticlePageLink,
  LayoutHero4 as ILayoutHero4,
} from 'sharedTypes';
import get from 'lodash/get';

interface Props {
  layoutModule: ILayoutHero4;
  hideSectionIcon?: boolean;
}

const Story: React.FC<{
  article: ArticleLink | FeatureArticlePageLink;
  hideSectionIcon?: boolean;
  className?: string;
}> = ({ article, hideSectionIcon, className = '' }) => {
  const isFiction = get(article, 'isFiction', false);
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  return (
    <div
      className={cx('flex flex-col items-center color-black', className, {
        'fiction-view': isFiction,
      })}
    >
      {hasSection && article.section && (
        <SectionButton
          className="mb1"
          section={article.section}
          hideIcon={hideSectionIcon}
          isFictionTeaser={isFiction}
        />
      )}
      <Button
        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
          title: article.title,
        })}
        to={linkTo}
        containerClassName="text-center"
        wrap={true}
      >
        <span className="linked-border-bottom-sm-on-hover-red text-section-title-md font-600 itc-cushing color-black">
          {curlyQuotes(article.title)}
        </span>
      </Button>
      {!!article.authors.length && (
        <AuthorButtons className="mt1 text-center" authors={article.authors} />
      )}
    </div>
  );
};

const LayoutHero4: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const { banner, article1, article2, article3 } = layoutModule;
  const { image, title, description, slug } = banner;

  return (
    <div className="LayoutHero4 inner-content-max-width mxauto content-padding-x pt1_5">
      <div className="teaser-widget-container layout-widget-container">
        <div className="LayoutHero4__banner-container relative pb2_5 md:pb1_5 flex flex-col md:flex-row color-black">
          <Button
            ariaLabel={Language.t('Global.navigateToPage', {
              slug,
            })}
            to={slug}
            className="relative w100 h100 radius-xs overflow-hidden relative"
            containerClassName="col-12 md:col-6"
          >
            <Img
              className="LayoutHero4__image grayscale-img w100 radius-xs"
              alt={image.alt || image.caption || ''}
              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540px, 1250px"
              src={sanityImgUtil(image, 1250)}
              srcSet={sanityImgSrcSetUtil(image, 768, 540, 1250)}
              dimensions={image.metadata && image.metadata.dimensions}
              crop={image.crop}
            />
            <Img
              className="LayoutHero4__image color-img absolute t0 b0 r0 l0 w100 radius-xs"
              alt={image.alt || image.caption || ''}
              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540px, 1250px"
              src={sanityImgUtil(image, 1250)}
              dimensions={image.metadata && image.metadata.dimensions}
              srcSet={sanityImgSrcSetUtil(image, 768, 540, 1250)}
            />
          </Button>
          <div className="mt1 md:mt2 md:pl2_5 col-12 md:col-6 flex flex-col justify-center items-center">
            <Button
              ariaLabel={Language.t('Global.navigateToPage', {
                slug,
              })}
              to={slug}
              containerClassName="mb1 text-center"
              wrap={true}
            >
              <span className="linked-border-bottom-lg-on-hover-red text-section-header-xl itc-cushing font-300 color-black">
                {curlyQuotes(title)}
              </span>
            </Button>
            <Button
              ariaLabel={Language.t('Global.navigateToPage', {
                slug,
              })}
              to={slug}
              containerClassName="text-center"
              wrap={true}
            >
              <span className="text-section-details-sm graebenbach font-400 color-gray-darker">
                {curlyQuotes(description)}
              </span>
            </Button>
          </div>
        </div>
        <div className="LayoutHero4__story-container flex mt1 md:mt1_5 overflow-x-scroll">
          <Story
            className="h100"
            article={article1}
            hideSectionIcon={hideSectionIcon}
          />
          <Story
            className="h100 LayoutHero4__middle-story relative"
            article={article2}
            hideSectionIcon={hideSectionIcon}
          />
          <Story
            className="h100"
            article={article3}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default LayoutHero4;
