import React from 'react';
import cx from 'classnames';

import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { curlyQuotes } from 'utils/text';

import { Button, Img } from 'components/base';
import SectionButton from 'components/SectionButton';
import AuthorButtons from 'components/AuthorButtons';

import Language from 'constants/Language';

import { ArticleLink, LayoutHero2 as ILayoutHero2 } from 'sharedTypes';
import get from 'lodash/get';

interface Props {
  layoutModule: ILayoutHero2;
  hideSectionIcon?: boolean;
}

const LayoutHero2: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const { article, showImage, showIntroText } = layoutModule;
  const image = article.featuredImage;
  const isFiction = get(article, 'isFiction', false);
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  return (
    <div className="LayoutHero2 inner-content-max-width mxauto content-padding-x sm:mt1_5">
      <div
        className={cx(
          'teaser-widget-container layout-widget-container color-black',
          {
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        {!!showImage && image && image.src && (
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            className="w100 h100"
            containerClassName={cx(
              'relative w100 radius-xs overflow-hidden md:mb_5',
              { 'fiction-teaser-img': isFiction }
            )}
          >
            <Img
              className="grayscale-img w100 radius-xs"
              alt={image.alt || image.caption || ''}
              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
              src={sanityImgUtil(image, 1920)}
              srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
              dimensions={image.metadata && image.metadata.dimensions}
              crop={image.crop}
            />
            <Img
              className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
              alt={image.alt || image.caption || ''}
              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
              src={sanityImgUtil(image, 1920)}
              dimensions={image.metadata && image.metadata.dimensions}
              srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
            />
          </Button>
        )}
        <div className="LayoutHero2__title-container relative flex flex-col items-center mb1 md:mb2_5 pt1 md:pt2">
          {hasSection && article.section && (
            <SectionButton
              className="mb1 md:mb2_25"
              section={article.section}
              hideIcon={hideSectionIcon}
              isFictionTeaser={isFiction}
            />
          )}
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            className="text-center color-black"
            containerClassName="mb1"
            wrap={true}
          >
            <span className="linked-border-bottom-lg-on-hover-red text-section-header-xl itc-cushing font-300">
              {curlyQuotes(article.title)}
            </span>
          </Button>
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            className="text-center"
            containerClassName="mb1"
            wrap={true}
          >
            <span className="text-section-details-sm graebenbach font-400 color-gray-darker text-center">
              {curlyQuotes(article.dek)}
            </span>
          </Button>
          {!!article.authors.length && (
            <AuthorButtons className="text-center" authors={article.authors} />
          )}
        </div>
        {!!showIntroText && (
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            wrap={true}
          >
            <div
              className={cx(
                'LayoutHero2__excerpt-container relative pt1 md:pt2_25 mt1_5 md:mt_5 color-black',
                { 'fiction-teaser-border': isFiction }
              )}
            >
              <p
                className={cx(
                  'LayoutHero2__excerpt text-layout-dropcaps text-section-body-sm graebenbach font-400',
                  { 'fiction-teaser-border': isFiction }
                )}
              >
                {curlyQuotes(article.excerpt)}
              </p>
              <div className="pt1_5">
                <span className="LayoutHero2__continue-reading relative color-black graebenbach text-section-body-sm font-400">
                  {Language.t('Global.continueReading')} &rarr;&#xFE0E;
                </span>
              </div>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default LayoutHero2;
