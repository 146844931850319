import React from 'react';
import cx from 'classnames';

import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { curlyQuotes } from 'utils/text';

import { Button, Img } from 'components/base';
import SectionButton from 'components/SectionButton';
import AuthorButtons from 'components/AuthorButtons';
import get from 'lodash/get';

import Language from 'constants/Language';

import { ArticleLink, LayoutHero1 as ILayoutHero1 } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutHero1;
  hideSectionIcon?: boolean;
}

const LayoutHero1: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const article = layoutModule.article1;
  const image = article.featuredImage;
  const isFiction = get(article, 'isFiction', false);
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  return (
    <div className="LayoutHero1 inner-content-max-width mxauto content-padding-x sm:mt1_5">
      <div
        className={cx(
          'teaser-widget-container layout-widget-container color-black',
          {
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        {image && image.src && (
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            className="w100 h100"
            containerClassName={cx('w100 h100', {
              'fiction-teaser-img': isFiction,
            })}
          >
            <div className="relative w100 radius-xs overflow-hidden">
              <Img
                className="grayscale-img w100 radius-xs"
                alt={image.alt || image.caption || ''}
                sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                src={sanityImgUtil(image, 1920)}
                srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                dimensions={image.metadata && image.metadata.dimensions}
                crop={image.crop}
              />
              <Img
                className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                alt={image.alt || image.caption || ''}
                sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                src={sanityImgUtil(image, 1920)}
                dimensions={image.metadata && image.metadata.dimensions}
                srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
              />
            </div>
          </Button>
        )}
        <div className="mt1 md:mt2_25 w100 flex flex-col xl:flex-row">
          <div
            className={cx(
              'LayoutHero1__title-container relative md:px1_5 pb2 md:pb4 col-12 xl:col-6 flex flex-col items-center',
              {
                'fiction-teaser-border': isFiction,
              }
            )}
          >
            {hasSection && article.section && (
              <SectionButton
                className="mb1 md:mb2_25"
                section={article.section}
                hideIcon={hideSectionIcon}
                isFictionTeaser={isFiction}
              />
            )}
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: article.title,
              })}
              to={linkTo}
              className="text-center color-black"
              containerClassName="mb1"
              wrap={true}
            >
              <span className="LayoutHero1__title linked-border-bottom-lg-on-hover-red text-section-header-lg itc-cushing font-300">
                {curlyQuotes(article.title)}
              </span>
            </Button>
            <span className="mb1 text-section-details-sm graebenbach font-400 color-gray-darker text-center">
              {article.dek ? curlyQuotes(article.dek) : null}
            </span>
            {!!article.authors.length && (
              <AuthorButtons
                className="text-center"
                authors={article.authors}
              />
            )}
          </div>
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={linkTo}
            className="color-black"
            containerClassName="col-12 xl:col-6 pt_75 sm:pt1_5 xl:pt0 pb3_25 md:pb0 md:px1_5"
            wrap={true}
          >
            <div
              className={cx('LayoutHero1__excerpt-container relative w100', {
                'fiction-teaser-border': isFiction,
              })}
            >
              <p className="text-layout-dropcaps text-section-body-sm graebenbach font-400">
                {article.excerpt ? curlyQuotes(article.excerpt) : null}
              </p>
              <div className="pt1_5">
                <span className="LayoutHero1__continue-reading relative color-black graebenbach text-section-body-sm font-400">
                  {Language.t('Global.continueReading')} &rarr;&#xFE0E;
                </span>
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LayoutHero1;
