import React from 'react';

import cx from 'classnames';
import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { curlyQuotes } from 'utils/text';

import { Button, Img } from 'components/base';
import HolidayCountdown from 'components/HolidayCountdown';
import SectionButton from 'components/SectionButton';
import AuthorButtons from 'components/AuthorButtons';

import Language from 'constants/Language';
import { RouteMap } from 'constants/RouteMap';

import {
  ArticleLink,
  FeatureArticlePageLink,
  LayoutHolidayHero as ILayoutHolidayHero,
} from 'sharedTypes';
import get from 'lodash/get';

interface Props {
  layoutModule: ILayoutHolidayHero;
  hideSectionIcon?: boolean;
}

const Story: React.FC<{
  article: ArticleLink | FeatureArticlePageLink;
  hideSectionIcon?: boolean;
  className?: string;
}> = ({ article, hideSectionIcon, className = '' }) => {
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  return (
    <div
      className={cx(
        'flex flex-col sm:items-center sm:justify-center color-black sm:text-center',
        className
      )}
    >
      {!hideSectionIcon && hasSection && article.section && (
        <SectionButton className="mb1" section={article.section} />
      )}
      <Button
        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
          title: article.title,
        })}
        to={linkTo}
        wrap={true}
      >
        <span className="linked-border-bottom-sm-on-hover-red LayoutHolidayHero__story-article-title podcast-title-sm itc-cushing font-600 color-black">
          {curlyQuotes(article.title)}
        </span>
      </Button>
      <Button
        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
          title: article.title,
        })}
        to={linkTo}
        wrap={true}
        containerClassName="mb_75 lg:mb0 lg:mt1 lg:text-center"
      >
        <span className="LayoutHolidayHero__story-article-description color-gray-darker graebenbach font-400">
          {curlyQuotes(article.brief ?? article.dek)}
        </span>
      </Button>
      {!!article.authors.length && (
        <AuthorButtons
          className="lg:mt1 lg:text-center"
          authors={article.authors}
        />
      )}
    </div>
  );
};

const LayoutHolidayHero: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
}) => {
  const {
    article1,
    article2,
    article3,
    article4,
    article5,
    holiday,
    holidayHeroColor,
  } = layoutModule;
  const colorIsBlack = holidayHeroColor === 'black';
  const articles = [article2, article3, article4, article5];

  const holidayHasLongTitle = holiday.title.length > 25;
  const hasSection = 'section' in article1 && article1.section;

  return (
    <div className="LayoutHolidayHero inner-content-max-width mxauto content-padding-x pt1_5">
      <div
        className={`LayoutHolidayHero__banner--${holidayHeroColor} w100 py_5 px1 lg:py1 flex justify-center items-center`}
      >
        <div
          className={cx(
            'LayoutHolidayHero__banner-detail-text graebenbach font-400 none lg:block lg:col-3 lg:pl1_5',
            {
              'color-gray': colorIsBlack,
              'color-red': !colorIsBlack,
            }
          )}
        >
          <HolidayCountdown
            className="LayoutHolidayHero__countdown flex items-center flex-wrap color-gray"
            startDate={holiday.startDate}
            endDate={holiday.endDate}
          />
        </div>
        <div
          className={cx(
            'LayoutHolidayHero__banner-title-text mx1 itc-cushing font-600 text-center lg:col-6',
            {
              'color-white': colorIsBlack,
              'color-red': !colorIsBlack,
            }
          )}
        >
          <span>
            {holidayHasLongTitle
              ? holiday.title
              : Language.t('LayoutHolidayHero.holidayEditions', {
                  title: holiday.title,
                })}
          </span>
        </div>
        <div className="none lg:flex lg:col-3 lg:pr1_5 flex-row justify-end border">
          <Button
            ariaLabel={Language.t('LayoutHolidayHero.moreButtonAriaLabel', {
              title: holiday.title,
            })}
            to={`${RouteMap.HOLIDAY.base}${holiday.slug}`}
            className={cx(
              'LayoutHolidayHero__banner-detail-text move-image-right-on-hover graebenbach font-400',
              {
                'color-gray': colorIsBlack,
                'color-red': !colorIsBlack,
              }
            )}
          >
            <span>
              {holidayHasLongTitle
                ? Language.t('Global.readMore')
                : Language.t('LayoutHolidayHero.moreButton', {
                    title: holiday.title,
                  })}
            </span>
            <div
              className={cx(
                'inline-block ml_25 button-move-arrow graebenbach text-article-details-xs',
                {
                  'color-red': !colorIsBlack,
                  'color-gray': colorIsBlack,
                }
              )}
            >
              &rarr;&#xFE0E;
            </div>
          </Button>
        </div>
      </div>
      <div
        className={cx(
          'LayoutHolidayHero__banner-detail-text py_75 graebenbach font-400 flex justify-center sm:none',
          {
            'color-gray-darker': colorIsBlack,
            'color-red': !colorIsBlack,
          }
        )}
      >
        <HolidayCountdown
          className="flex items-center justify-center flex-wrap"
          startDate={holiday.startDate}
          endDate={holiday.endDate}
        />
      </div>
      <div className="LayoutHolidayHero__container teaser-widget-container layout-widget-container sm:mt1_5 flex flex-col lg:flex-row">
        <div
          className={cx(
            'LayoutHolidayHero__banner-detail-text pb2 pt_5 graebenbach font-400 flex justify-center none sm:flex lg:none',
            {
              'color-gray-darker': colorIsBlack,
              'color-red': !colorIsBlack,
            }
          )}
        >
          <HolidayCountdown
            className="flex items-center justify-center flex-wrap"
            startDate={holiday.startDate}
            endDate={holiday.endDate}
          />
        </div>
        <div className="LayoutHolidayHero__article1-container col-12 lg:col-9 lg:pr1_5">
          <div className="color-black">
            {article1.featuredImage && article1.featuredImage.src && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: article1.title,
                })}
                // to={generateArticleUrl(article1)}
                to={
                  'section' in article1 && article1.section
                    ? generateArticleUrl(article1 as ArticleLink)
                    : `/feature/${article1.slug}`
                }
                className="relative w100 radius-xs overflow-hidden"
                containerClassName="w100"
                wrap={true}
              >
                <Img
                  className="grayscale-img w100 radius-xs"
                  alt={
                    article1.featuredImage.alt ||
                    article1.featuredImage.caption ||
                    ''
                  }
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(article1.featuredImage, 1920)}
                  srcSet={sanityImgSrcSetUtil(
                    article1.featuredImage,
                    768,
                    1080,
                    1920
                  )}
                  dimensions={
                    article1.featuredImage.metadata &&
                    article1.featuredImage.metadata.dimensions
                  }
                  crop={article1.featuredImage.crop}
                />
                <Img
                  className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                  alt={
                    article1.featuredImage.alt ||
                    article1.featuredImage.caption ||
                    ''
                  }
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(article1.featuredImage, 1920)}
                  dimensions={
                    article1.featuredImage.metadata &&
                    article1.featuredImage.metadata.dimensions
                  }
                  srcSet={sanityImgSrcSetUtil(
                    article1.featuredImage,
                    768,
                    1080,
                    1920
                  )}
                />
              </Button>
            )}
            <div
              className={cx(
                'w100 flex flex-col lg:flex-row lg:justify-center',
                {
                  'mt_75 lg:mt2_25': article1.featuredImage,
                  mt1_5: !article1.featuredImage,
                }
              )}
            >
              <div className="relative lg:px1_5 pt1 pb2 lg:pb3 flex flex-col items-center">
                {!hideSectionIcon && hasSection && article1.section && (
                  <SectionButton
                    className="mb1 lg:mb2_25"
                    section={article1.section}
                  />
                )}
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: article1.title,
                  })}
                  to={
                    'section' in article1 && article1.section
                      ? generateArticleUrl(article1 as ArticleLink)
                      : `/feature/${article1.slug}`
                  }
                  containerClassName="mb1 text-center"
                  wrap={true}
                >
                  <span className="LayoutHolidayHero__article1-title  linked-border-bottom-lg-on-hover-red text-section-header-lg itc-cushing font-300 color-black">
                    {curlyQuotes(article1.title)}
                  </span>
                </Button>
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: article1.title,
                  })}
                  to={
                    'section' in article1 && article1.section
                      ? generateArticleUrl(article1 as ArticleLink)
                      : `/feature/${article1.slug}`
                  }
                  containerClassName="mb1 text-center"
                  wrap={true}
                >
                  <span className="text-section-details-sm graebenbach font-400 color-gray-darker">
                    {curlyQuotes(article1.brief ?? article1.dek)}
                  </span>
                </Button>
                {!!article1.authors.length && (
                  <AuthorButtons
                    className="text-center"
                    authors={article1.authors}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'LayoutHolidayHero__articles-container lg:col-3 lg:pr1_5 overflow-x-scroll flex flex-row',
            {
              'LayoutHolidayHero__articles-container--4articles': article5,
            }
          )}
        >
          {articles &&
            articles.map((article, i) => {
              if (article) {
                return (
                  <Story
                    key={`layout-holiday-hero-${article.title}`}
                    hideSectionIcon={true}
                    className={cx(
                      'LayoutHolidayHero__simple-article col-12 mt1 px1 lg:mt0 lg:px0 lg:ml1_5 lg:pb2 lg:pt2',
                      {
                        'LayoutHolidayHero__simple-article--no-line':
                          article5 && i === 1,
                      }
                    )}
                    article={article}
                  />
                );
              }

              return null;
            })}
        </div>
      </div>
      <div className="sm:none black border-bottom-black flex justify-center py1">
        <Button
          ariaLabel={Language.t('LayoutHolidayHero.moreButtonAriaLabel', {
            title: holiday.title,
          })}
          to={`${RouteMap.HOLIDAY.base}${holiday.slug}`}
          className={cx(
            'LayoutHolidayHero__banner-detail-button move-image-right-on-hover graebenbach font-400 color-black'
          )}
        >
          <span>
            {Language.t('LayoutHolidayHero.moreButton', {
              title: holiday.title,
            })}
          </span>
          <div className="inline-block ml_25 button-move-arrow graebenbach text-article-details-xs color-black">
            &rarr;&#xFE0E;
          </div>
        </Button>
      </div>
    </div>
  );
};

export default LayoutHolidayHero;
